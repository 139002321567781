import MuiAppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { IMenuItem } from '../../../hooks/useLayout';
import { CiscoLogo } from '../../logos/cisco-logo/CiscoLogo';
import SignOutButton from '../../sign-out-button/SignOutButton';
import useStyles from '../Layout.styles';

export interface AppBarProps {
  title?: string;
  file?: any;
  signInButtonLabel?: string;
  signOutButtonLabel?: string;
  handleDrawerOpen?: () => void;
  handleDrawerClose?: () => void;
  menus: IMenuItem[];
  open?: boolean;
  keycloak: Keycloak.KeycloakInstance;
}

export const AppBar: FunctionComponent<AppBarProps> = (props) => {
  const classes = useStyles();
  const {
    signInButtonLabel,
    signOutButtonLabel,
    open,
    handleDrawerOpen,
    handleDrawerClose,
    keycloak,
  } = props;
  const [isRoger, setIsRoger] = useState(false);

  useEffect(() => {
    setIsRoger(
      (props.keycloak?.idTokenParsed as any)?.email === 'roger.haney@cdw.com'
    );
  }, []);

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton)}
        >
          <MenuIcon />
        </IconButton>
        {!isRoger && (
          <Box id="company-logo" className={classes.image}>
            <Box height={'39px'} width={'55px'}>
              <CiscoLogo
                fontSize={'inherit'}
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          </Box>
        )}
        <Typography
          variant="subtitle1"
          noWrap
          style={{
            marginLeft: 10,
            fontFamily: 'Futura, Tw Cen MT',
            fontWeight: 900,
          }}
        >
          {props.title}
        </Typography>
        <Grid item className={classes.menu}>
          <SignOutButton
            {...({ signInButtonLabel, signOutButtonLabel, keycloak } as any)}
          />
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
