import { Button } from '@material-ui/core';
import React from 'react';
import { FeedbackDialog } from '../../../components/feedback/FeedbackDialog';
import useFeedbackDialog from '../../../hooks/useFeedbackDialog';

export const FooterContent: React.FC<{}> = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '1rem',
      }}
    >
      <Button variant="outlined" size="small" disabled>
        &copy; {new Date().getFullYear()}
      </Button>
      <div style={{ width: '1rem' }}></div>
      <FeedbackDialog useFeedbackDialog={useFeedbackDialog} />
    </div>
  );
};
