import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import React from 'react';

import { IMenuItem } from '../../../hooks/useLayout';
import DrawerMenuItemComponent from '../drawer-menu-item/DrawerMenuItem';
import { handleMenuClick } from '../nav-drawer/NavDrawer';
import PopoverMenu from '../popover-menu/PopoverMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
    },
    menuItemIcon: {
      color: theme.palette.text.primary,
      marginLeft: '8px',
    },
  })
);

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  icon: PropTypes.elementType,
  menuItems: PropTypes.array,
  onClick: PropTypes.any,
};

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>;

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  menuItems?: IMenuItem[];
};

const DrawerMenuItemList: React.FC<AppMenuItemProps> = (props) => {
  const {
    label,
    url,
    icon: Icon,
    menuItems = [],
    onClick = handleMenuClick,
  } = props;
  const classes = useStyles();
  const isExpandable = menuItems && menuItems.length > 0;

  const handleClick = () => {
    onClick(props);
  };

  const MenuItemRoot = (
    <DrawerMenuItemComponent
      className={classes.menuItem}
      link={url}
      onClick={handleClick}
    >
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={label} inset={!Icon} />
      {isExpandable && <ChevronRightIcon />}
    </DrawerMenuItemComponent>
  );

  const PopoverMenuItemRoot = (
    <PopoverMenu menuItems={menuItems}>{MenuItemRoot}</PopoverMenu>
  );
  const MenuItemChildren = isExpandable ? (
    <PopoverMenu menuItems={menuItems} />
  ) : null;

  return (
    <>
      {isExpandable ? PopoverMenuItemRoot : MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default DrawerMenuItemList;
