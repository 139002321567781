import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { navigate } from 'gatsby';
import { KeycloakInstance } from 'keycloak-js';
import React, { FunctionComponent } from 'react';
import ROUTES from '../../routes';

export interface SignOutButtonProps {
  signInButtonLabel: string;
  signOutButtonLabel: string;
  user?: any;
  signOut: () => any;
  keycloak: KeycloakInstance;
}

export const SignOutButton: FunctionComponent<SignOutButtonProps> = (props) => {
  const signInHandler = async () => navigate(ROUTES.LOGIN);

  return (
    <Button
      color="inherit"
      onClick={
        props.keycloak?.authenticated
          ? props.signOut || props.keycloak?.logout
          : (signInHandler as any)
      }
      endIcon={props.keycloak?.authenticated && <ExitToAppIcon />}
      data-testid={'sign-in-out-button'}
    >
      {props.keycloak?.authenticated
        ? props.signOutButtonLabel
        : props.signInButtonLabel}
    </Button>
  );
};

export default SignOutButton;
