import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { navigate } from 'gatsby';
import React, { FunctionComponent } from 'react';

import { IMenuItem } from '../../../hooks/useLayout';
import DrawerMenu from '../drawer-menu/DrawerMenu';
import useStyles from '../Layout.styles';

export interface NavDrawerProps {
  open: boolean;
  handleDrawerClose: () => void;
  menus: IMenuItem[];
}

export const handleMenuClick = async (menu: IMenuItem) => {
  if (!menu?.isExternal && menu?.url) return navigate(menu.url);
  if (menu?.isExternal && menu?.url) window.open(menu.url, '_blank');
};

const NavDrawer: FunctionComponent<NavDrawerProps> = (props) => {
  const classes = useStyles();
  const { open } = props;

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <DrawerMenu menus={props.menus} onClick={handleMenuClick} />
    </Drawer>
  );
};

export default NavDrawer;
