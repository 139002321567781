import { Button, Dialog, DialogContent } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import React from 'react';
import defaultUseFeedbackDialog from '../../hooks/useFeedbackDialog';
import FeedbackForm from './FeedbackForm';

const staticText = {
  buttonText: 'Leave Feedback',
};

export interface FeedbackDialogProps {
  useFeedbackDialog: typeof defaultUseFeedbackDialog;
}

export const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
  useFeedbackDialog = defaultUseFeedbackDialog,
}) => {
  const {
    open,
    handleOpen,
    handleClose,
    handleFeedbackSubmitted,
    handleFeedbackCancelled,
  } = useFeedbackDialog();

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        startIcon={<CommentIcon />}
        onClick={handleOpen}
      >
        {staticText.buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-dialog"
      >
        <DialogContent>
          <FeedbackForm
            handleFeedbackCancelled={handleFeedbackCancelled}
            handleFeedbackSubmitted={handleFeedbackSubmitted}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
