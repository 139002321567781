import { useContext, useState } from 'react';
import { FeedbackFormFields } from 'src/components/feedback/FeedbackForm';
import { SnackBarContext } from '../providers/SnackbarProvider';

export const useFeedbackDialog = () => {
  const [open, setOpen] = useState(false);
  const snackbar = useContext(SnackBarContext);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleFeedbackCancelled = () => {
    handleClose();
  };

  const handleFeedbackSubmitted = (data: FeedbackFormFields): void => {
    try {
      console.log('feedback:', data);

      snackbar.showFeedback({
        message: 'Feedback submitted. Thank you!',
        duration: 3500,
      });
      handleClose();
    } catch (error) {
      snackbar.showFeedback({
        message: 'Unable to submit feedback',
        duration: 3500,
        severity: 'error',
      });
      console.error(error);
      handleClose();
    }
  };

  return {
    open,
    handleOpen,
    handleClose,
    handleFeedbackCancelled,
    handleFeedbackSubmitted,
  };
};

export default useFeedbackDialog;
