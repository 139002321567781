import { SetStateAction, useState } from 'react';

import storage from '../util/storage';

export interface UseNavigationDrawer {
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  open: boolean;
  setOpen: SetStateAction<boolean> | any;
}

export default (
  isOpen = !!storage.load('isNavDrawerOpen') || false
): UseNavigationDrawer => {
  const [open, setOpen] = useState(isOpen);

  const handleDrawerOpen = () => {
    setOpen(true);
    storage.save('isNavDrawerOpen', 'true');
  };

  const handleDrawerClose = () => {
    setOpen(false);
    storage.remove('isNavDrawerOpen');
  };

  return {
    handleDrawerOpen,
    handleDrawerClose,
    open,
    setOpen,
  };
};
