import { useState } from '@hookstate/core';
import { Validation } from '@hookstate/validation';
import Button from '@material-ui/core/Button';
import { CardProps } from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      maxWidth: 350,
    },
    fields: {
      marginTop: theme.spacing(1.5),
      width: '100%',
    },
    formControl: {
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export interface FeedbackFormFields {
  text: string;
  textStarted: boolean;
}

export interface FeedbackFormProps extends Partial<CardProps> {
  handleFeedbackSubmitted: (data: FeedbackFormFields) => void;
  handleFeedbackCancelled: () => void;
}

export const FeedbackForm: React.FC<FeedbackFormProps> = ({
  children,
  handleFeedbackSubmitted,
  handleFeedbackCancelled,
}) => {
  const classes = useStyles();
  const formSubmitted = useState<boolean>(false);
  const formState = useState<FeedbackFormFields>({
    text: '',
    textStarted: false,
  });
  formState.attach(Validation);

  Validation(formState.text).validate(
    (text) => text.trim() !== '' && text.length <= 500,
    'Feedback cannot be empty and is limited to 500 characters'
  );

  const handleSubmit = () => {
    if (!Validation(formState.text).valid()) {
      formState.textStarted.set(true);
      return;
    }

    formSubmitted.set(true);

    handleFeedbackSubmitted && handleFeedbackSubmitted({ ...formState.value });
  };

  const handleCancel = () => {
    handleFeedbackCancelled && handleFeedbackCancelled();
  };

  const textError =
    formState.textStarted.value &&
    !Validation(formState.text).valid() &&
    Validation(formState.text).errors()[0]?.message;

  return (
    <>
      <CardContent>
        <Typography variant={'body1'} color="textSecondary">
          Please leave feedback on anything you would like to see changed,
          added, or removed.
        </Typography>
        <TextField
          className={classes.fields}
          id="submit-feedback"
          label="Feedback"
          variant="outlined"
          multiline={true}
          value={formState.text.value}
          error={Boolean(textError)}
          helperText={textError}
          onChange={(event) =>
            formState.merge({
              text: event.target.value,
              textStarted: true,
            })
          }
        />
        {children}
      </CardContent>
      <CardActions>
        <Button onClick={() => handleCancel()}>Cancel</Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => handleSubmit()}
          disabled={formSubmitted.get()}
        >
          Submit Feedback
        </Button>
      </CardActions>
    </>
  );
};

export default FeedbackForm;
